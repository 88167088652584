import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ItemData } from 'src/app/interfaces/items';
import { MatSnackBar } from '@angular/material';
import { CategoriesService } from 'src/app/modules/shared/services/categories.service';
import { ItemsService } from 'src/app/modules/shared/services/items.service';
import { AssetsService } from 'src/app/modules/shared/services/assets.service';

@Component({
  selector: 'app-view-products-categories',
  templateUrl: './view-products-categories.component.html',
  styleUrls: ['./view-products-categories.component.scss']
})

export class ViewProductsCategoriesComponent implements OnInit , OnDestroy {
  catId: number;
  products: ItemData[];
  filteredProducts;
  filteredProductsByCat:ItemData[]; // all cat in the begin
  categories$;
  subscription: Subscription;
  selectedCategory: string ="كل الأقسام";

  dtTrigger = new Subject();

  displayedColumns: string[] = ['image', 'name', 'body', 'price', 'catogery_id', 'delete', 'edit'];

  // @ViewChild(MatTable,{static:true}) table: MatTable<any>;
  // @ViewChild(MatSort,{static:true}) sort: MatSort;
  // @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  
  // ngAfterViewInit() {
  //  setTimeout(() => {
  //   this.paginator._intl.itemsPerPageLabel = 'عدد العناصر في كل صفحة';
  //   this.filteredProducts.paginator = this.paginator;
  //  }, 100);
    
  // }


  constructor(
    private snackBar: MatSnackBar,
    private cat: CategoriesService, 
    private items: ItemsService,
    private assets: AssetsService,
    ) { }

  ngOnInit() {

    this.categories$ = this.cat.getCategories(); 
    
     this.subscription = this.items.getItems().subscribe(res=>{ 
     this.filteredProducts = res;
     this.filteredProductsByCat = this.filteredProducts = this.products = res;
    });

  }

  deleteAlert(id){
    this.assets.deleteAlert(id).subscribe(res=> res? this.deleteProduct(id):false) ;
 }

 private deleteProduct(id){
    this.items.deleteProduct(id).subscribe(res=> {
      let itemIndex = this.filteredProducts.findIndex( item =>{ return item.id === id });
      
      this.filteredProducts.splice(itemIndex, 1);

      this.filteredProducts = this.filteredProducts;
      
      this.snackBar.open('تم حذف المنتج بنجاح ', `` , {duration: 1500});
      
    },
    error=> this.snackBar.open('حدثت مشكلة بالاتصال بالسيرفر برجاء المحاولة مرة أخرى', `` , {duration: 1500}));
 }

  filteredCategory(value, catName){
    this.selectedCategory = catName;

    this.filteredProducts = (value) ?
    this.products.filter(p => p.catogery_id === value) : this.products ;
    
    this.filteredProductsByCat = this.filteredProducts; // using in search filter
  }

  allCategories(){
    this.filteredProductsByCat = this.filteredProducts = this.products;
    this.selectedCategory="كل الأقسام";

  }

  applyFilter(value:string){
    
    this.filteredProducts = (value) ?
    this.filteredProductsByCat.filter( (p:any) => p.name.toLowerCase().includes(value.trim().toLowerCase())) : this.filteredProductsByCat ; 
  
    // this.filteredProducts.filter = value.trim().toLowerCase();
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
