import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { SettingService } from 'src/app/modules/shared/services/setting.service';
import { AssetsService } from 'src/app/modules/shared/services/assets.service';

@Component({
  selector: 'app-app-slider',
  templateUrl: './app-slider.component.html',
  styleUrls: ['./app-slider.component.scss']
})
export class AppSliderComponent implements OnInit , OnDestroy {
  imgUrl = 'assets/images/phone_upload.jpg';

  slider: FormData = new FormData();
  sliders;
  subscription: Subscription;

  imageFile: any = null; // for uploaded image

  constructor(private setting:SettingService, private snackBar: MatSnackBar, private assets: AssetsService) { }

  ngOnInit() {
    this.getSliders();
  }

  private getSliders(){
    this.subscription = this.setting.getSliders().subscribe(res => this.sliders = res);

  }

  addSlider(){
      this.slider.append('img', this.imageFile, this.imageFile.name);
      this.setting.addSlider(this.slider)
      .subscribe((res: {data}) => {
         this.sliders.push(res.data); // push to the view
         this.resetInputs(); // reset inputs
         this.snackBar.open('تم اضافة سلايدر جديد', `` , {duration: 1500})

    }, () =>  this.snackBar.open('حدثت مشكلة بالاتصال بالسيرفر برجاء المحاولة مرة أخرى', `` , {duration: 1500})
    );
  }

  private resetInputs(){
    this.imgUrl = 'assets/images/default-slider.png';
    this.imageFile = null;
  }

  deleteAlert(id){
    this.assets.deleteAlert(id).subscribe(res=> res? this.deleteSlider(id):false) ;
  }

  private deleteSlider(id){

    this.setting.deleteSlider(id)
     .subscribe(
       res=> {
        this.snackBar.open('تم حذف السلايدر بنجاح', `` , {duration: 1500});
        this.getSliders();
      },
        () =>  {
          this.snackBar.open('حدثت مشكلة بالاتصال بالسيرفر برجاء المحاولة مرة أخرى', `` , {duration: 1500});
        }
      );
  }

  imageUpload(event){
    if(event.target.files){
     this.imageFile = event.target.files[0];
    
     var render = new FileReader();    
     render.readAsDataURL(this.imageFile);
     render.onload = (event: any) =>  this.imgUrl = event.target.result ;
  }
}

ngOnDestroy(){
  this.subscription.unsubscribe();
}
}
