// login url
export const loginUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/auth/admin_login'; 
export const postMessageUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/contact_request'; 

export const getCategoriesUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/categories';
export const deleteCategoryUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/delete_category/';
export const updateCategoryUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/edit_category';
export const addCategoryUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/add_category';

// Sales
export const postSalesUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/auth/register_sales';
export const getSalesUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/auth/sales';
export const getSpecificSalerUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/auth/user_data/';
export const deleteSallerUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/auth/delete_sales/';
export const updateSallerUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/auth/update';

// users
export const getUsersUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/all_users';
export const getUsersSearchUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/users_search/';
export const deleteUserUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/auth/delete_sales/';

// graph data
export const getCurrentMonthDatahUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/graph_data';
export const getSpecificMonthDataUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/month_data/';

//items
export const getItemsUrl = 'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/items';
export const postItemUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/add_item';
export const deleteItemUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/delete_item/';
export const getItemUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/item_data/';
export const updateItemUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/update_item/';

//app slider
export const addAppSliderUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/add_slider';
export const getAppSlidersUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/mobile_sliders';
export const deleteAppSliderUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/web/delete_slider/';

// dashboard
// all orders
export const getOrdersUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/all_orders';
export const getInProgressOrdersUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/all_do_orders';
export const getHomeOrdersDataUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/home_order_data';
export const updateOrderStatusUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/update_order_status/';

//branches
export const getBranchesUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/all_branches';
export const addBranchUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/add_branch';
export const deleteBranchUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/delete_branch/';
export const updateBranchUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/update_branch/';

//services
export const getServicesUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/all_services';
export const addServiceUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/add_service';
export const deleteServiceUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/delete_service/';
export const updateServiceUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/update_service/';
export const getSpecificServiceUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/spesific_services/';

//about
export const getAboutUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/get_about_us/';
export const updateAboutUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/update_about_us';

// sliders
export const getSlidersUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/all_sliders';
export const addSliderUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/add_slider';
export const deleteSliderUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/delete_slider/';
export const updateSliderUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/update_slider/';
export const getSpecificSliderUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/spesific_slider/';

// contacts
export const getContactsUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/get_content_us/';
export const updateContactsUrl =  'https://cors-anywhere.herokuapp.com/https://fontyback.clicktopass.com/api/dashboard/content_us';