import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { take } from 'rxjs/operators';
import { DialogComponent } from 'src/app/modules/material/components/dialog/dialog.component';
import { UsersService } from 'src/app/modules/shared/services/users.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
  users ;
  filteredUsers ;
  
  // displayedColumns: string[] = ['name', 'phone', 'email', 'delete', 'edit'];

  // @ViewChild(MatTable,{static: false}) table;
  // @ViewChild(MatPaginator, {static:false}) paginator;

  constructor(
    private dialog: MatDialog, 
    private user: UsersService,
    private snackBar: MatSnackBar) { }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.filteredUsers.paginator = this.paginator;    
  //     this.paginator._intl.itemsPerPageLabel = 'عدد العناصر في كل صفحة';      
  //   }, 100);
  // }

  ngOnInit() {
    this.user.getSalles().subscribe((res: any)=> {
      this.filteredUsers= this.users = res;
    });
  }

  deleteAlert(id){
    this.dialog.open(DialogComponent)
    .afterClosed().pipe(take(1))
    .subscribe(res=>{
      if(res === "yes") this.deleteUser(id);
    });
  }

  private deleteUser(id){
    // optimistic update
    let itemIndex = this.users.data.findIndex( (item:any) =>{ return item.id === id });
    var deletedItem = this.users.data.splice(itemIndex, 1);
    this.filteredUsers.data = this.users.data;

    this.user.deleteSaller(id).subscribe(
    data=>  this.snackBar.open('تم حذف المستخدم بنجاح ', `` , {duration: 1500})

    , error=> {
      this.users.data.splice(itemIndex, 0, deletedItem[0]);

      error=> this.snackBar.open('حدثت مشكلة بالاتصال بالسيرفر برجاء المحاولة مرة أخرى', `` , {duration: 1500});
    });
  }
  
  filter(value){
    this.filteredUsers = (value) ?
    this.users.filter( (p:any) => p.sales.name.toLowerCase().includes(value.trim().toLowerCase())) : this.users ; 
  }
}
