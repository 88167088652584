import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.scss']
})
export class SiteSettingsComponent implements OnInit {

 type:string ="siteSettings";

  constructor() { }

  ngOnInit() {
  }

}
